/* 
===============
Global Styling
===============
*/

:root {
  --backgroundColor1: #27292e;
  --backgroundColor2: #35363b;
  --primaryColor: #07aaf5;
  --secondaryColor: #db2c40;
  --textColor1: #ffffff;
  --textColor2: #d4d4d5;
  --maxWidth: 1300px;
}

html {
  scroll-behavior: smooth;
  background-color: var(--backgroundColor1);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--textColor1);
  font-weight: 500;
  line-height: 1.5em;
}

h1 {
  font-size: clamp(1rem, 6vw, 2rem);
}
h2 {
  color: var(--primaryColor);
  font-size: 2.2rem;
}

p,
h3 {
  font-weight: 500;
  color: var(--textColor2);
  font-size: 1.2rem;
  line-height: 1.5em;
}
h3 {
  font-size: clamp(1rem, 3vw, 1.5rem);
}

span {
  color: var(--primaryColor);
}

section {
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* Buttons--------------------------------------------------------------------- */
.btn-primary {
  background-color: var(--primaryColor);
  color: black;
  border-radius: 10px;
  border: none;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  transition-duration: 0.1s;
  box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.25);
}
.btn-primary:hover {
  transform: scale(1.1);
}
.btn-primary:active {
  transform: scale(0.9);
}

.btn-secondary {
  background-color: var(--secondaryColor);
  color: white;
  border-radius: 10px;
  border: none;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  transition-duration: 0.1s;
  box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.25);
}
.btn-secondary:hover {
  transform: scale(1.1);
}
.btn-secondary:active {
  transform: scale(0.9);
}
/* ---------------------------------------------------------------------------- */

.headline {
  margin-top: 6rem;
}

.underline {
  height: 5px;
  width: 50%;
  background-color: var(--backgroundColor2);
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 3em;
}

a {
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.headline {
  font-size: clamp(1.3rem, 6vw, 2.5rem);
}
/* ---------------------------------------------------------------------------- */

/* Scrollbar ------------------------------------------------------------------ */

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--backgroundColor2);
  border-radius: 0px 0px 10px 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--backgroundColor1);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* ---------------------------------------------------------------------------- */
